function initializeHeader() {
  const burgerIcon = document.getElementById('burgerIcon');
  const mobileMenu = document.getElementById('mobileMenu');

  const handleBurger = () => {
    burgerIcon.addEventListener('click', (e) => {
      e.preventDefault();
      toggleMobileMenu();
    });
  };

  const toggleMobileMenu = () => {
    mobileMenu.classList.toggle('header-menu__items_mb_hidden');
    burgerIcon.classList.toggle('header-menu__burger_open');
  };

  handleBurger();
}
// document.addEventListener("turbolinks:load", header)
document.addEventListener("DOMContentLoaded", initializeHeader)
