// const $submitButtons = document.getElementsByClassName('event-card__submit');
function initializeSubmit() {
  const $submitButtons = document.querySelectorAll('[data-event-id]');
  const $submitOverlays = document.getElementsByClassName('ste__overlay');
  const $closeButtons = document.getElementsByClassName('ste__close');
  const $submitForms = document.getElementsByClassName('ste__form');

  const closeAllSubmitForms = () => {
    for (let i = 0; i < $submitOverlays.length; ++i) {
      $submitOverlays[i].className = 'ste__overlay is-hidden';
      document.body.classList.remove('body_overlay');
    }
  };

  const handleSubmit = () => {
    for (let i = 0; i < $submitForms.length; ++i) {
      const thisForm = $submitForms[i];

      thisForm.addEventListener('ajax:success', () => {
        const sentMessage = Array.from(thisForm.childNodes).filter((node) => {
          return node.classList && node.classList.contains('ste__sent');
        });
        sentMessage[0].classList.remove('is-hidden');
        setTimeout(() => { closeAllSubmitForms(); }, 2000);
      });

      thisForm.addEventListener('ajax:error', (e) => {

        const { errors } = e.detail[0];
        console.log(errors);

        if (errors) {
          const formFields = Array.from(thisForm.childNodes).filter((node) => {
            return node.classList && node.classList.contains('ste__field');
          });

          for (var i = 0; i < formFields.length; i++) {
            const field = formFields[i];
            if (errors.hasOwnProperty(field.dataset.label)) {
              field.classList = 'ste__field has-error';
            } else {
              field.classList = 'ste__field';
            }
          }
        }
      });

    }
  };

  const handleClickOnSubmitButtons = () => {
    for (let i = 0; i < $submitButtons.length; ++i) {

      $submitButtons[i].addEventListener('click', (e) => {
        e.preventDefault();
        const id = e.target.dataset.eventId ? e.target.dataset.eventId : e.target.parentNode.dataset.eventId;
        openSubmitForm(id);
      });
    }
  };

  const handleCloseAction = () => {
    for (let i = 0; i < $closeButtons.length; ++i) {
      $closeButtons[i].addEventListener('click', (e) => {
        e.preventDefault();
        closeAllSubmitForms();
      });
    }
    for (let i = 0; i < $submitOverlays.length; ++i) {
      $submitOverlays[i].addEventListener('click', (e) => {
        if (e.target.classList.contains('ste__overlay')) {
          closeAllSubmitForms();
        }
      });
    }
  };

  const openSubmitForm = (id) => {
    const form = document.getElementById(`ste-id-${id}`);
    form.classList.remove('is-hidden');
    document.body.classList.add('body_overlay');
  };

  handleClickOnSubmitButtons();
  handleSubmit();
  handleCloseAction();
}

// document.addEventListener("turbolinks:load", initializeSubmit)
document.addEventListener("DOMContentLoaded", initializeSubmit)